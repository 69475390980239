import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"

import Header from 'components/molecules/Header';
import Footer from 'components/molecules/Footer';
import favicon from 'assets/favicon.ico';
import cover from 'assets/images/cover.png';

import '../styles/_index.module.scss';

class Layout extends React.PureComponent {

  render() {
    const { children, title } = this.props;
    return (
      <div>
        <StaticQuery
          query={graphql`
            query HeadingQuery {
              site {
                siteMetadata {
                  title
                  description
                  keywords
                  url
                }
              }
            }
          `}
          render={data => (
            <Helmet>
              <title>{title ? `${title} - ` : ''}{data.site.siteMetadata.title}</title>
              <meta name="description" content={data.site.siteMetadata.description} />
              <meta name="keywords" content={data.site.siteMetadata.keywords} />
              <meta name="keywords" content={data.site.siteMetadata.keywords} />
              <meta property="og:url" content={data.site.siteMetadata.url} />
              <meta property="og:type" content="product" />
              <meta property="og:title" content="Direito Anotado" />
              <meta property="og:description" content={data.site.siteMetadata.description} />
              <meta property="og:image" content={`${data.site.siteMetadata.url}${cover}`} />
              <meta property="fb:app_id" content="840402969451384" />
              <script async src="https://www.googletagmanager.com/gtag/js?id=UA-115472575-1" />
              <link rel="shortcut icon" type="image/x-icon" href={favicon} />
              <script dangerouslySetInnerHTML={`window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments); }gtag('js', new Date());gtag('config', 'UA-115472575-1');`} />
              <meta name="google-site-verification" content="rJx9bEFDqr770_dEZNmnRpUBGp3_Iis_kCtB4aMZ8W0" />
            </Helmet>
          )}
        />
        <Header />
        {children}
        <Footer />
      </div>
    );
  }
}

export default Layout;
