import React from 'react';
import c from 'classnames';
import { Link } from 'gatsby';

import helpers from 'styles/_helpers.module.scss';
import styles from './styles.module.scss';

const Footer = () => (
  <footer >
    <div className={c(styles.content_wrapper, styles.footer_grid)}>
      <div>
        <h3 className={c(helpers.mb20, styles.section_title)}>anotado</h3>
        <Link to='/produtos'>Produtos</Link>
        <Link to='/autores'>Autores</Link>
        <Link to='/parceiros'>Parceiros</Link>
      </div>
      <div>
        <h3 className={c(helpers.mb20, styles.section_title)}>fale conosco</h3>
        <a
          href='https://www.facebook.com/direitoanotadobr'
        >
          Facebook
        </a>
        <a
          href='https://www.instagram.com/direitoanotadobr/?hl=pt-br'
        >
          Instagram
        </a>
        <a
          href='mailto:cpc.anotado@direitoanotado.com.br'
        >
          Email
        </a>
      </div>
      <div>
        <h3 className={c(helpers.mb20, styles.section_title)}>ajuda</h3>
        <a href='http://anotado.zendesk.com/'>FAQs</a>
        <Link to='/termosecondicoes'>Termos e condições</Link>
        <Link to='/politicadeprivacidade'>
          Política de Privacidade
        </Link>
      </div>
    </div>
    <a
      className={styles.red}
      href='https://weareredlight.com/'
    >
      Made With Love by Redlight
    </a>
  </footer>
);

Footer.propTypes = {
};

export default Footer;
