import React from 'react';
import { Link } from 'gatsby';

import logoSrc from 'assets/images/logo.svg';
import styles from './styles.module.scss';

const Header = () => (
  <div className={styles.nav_wrapper}>
    <Link to='/' className={styles.active}>
      <img src={logoSrc} alt="" />
    </Link>
    <div>
      <Link
        to='/'
        activeClassName={styles.active}
      >
        anotado
      </Link>
      <Link
        to='/produtos'
        activeClassName={styles.active}
        className={styles.center_margin}
      >
        produtos
      </Link>
      <Link
        to='/autores'
        activeClassName={styles.active}
        className={styles.margin_r}
      >
        autores
      </Link>
      <Link
        to='/parceiros'
        activeClassName={styles.active}
      >
        parceiros
      </Link>
    </div>
  </div>
);

export default Header;
